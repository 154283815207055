import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
// storage
import { getToken, setToken, setAntiCheat } from "@/helpers/storage";
import constants from '@/constants'


export function checkAccessMiddleware(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
  try {    
    // MOBILE
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      return
    }

    if (process.env.VUE_APP_BUILD === "DESKTOP-APP") {
      const indexAntiCheat = process.argv.findIndex((arg) => arg.startsWith("--antiCheat"))
      if (indexAntiCheat >= 0) {
        const antiCheat = process.argv[indexAntiCheat].split("=")[1]
        setAntiCheat(antiCheat)
        process.argv.splice(indexAntiCheat, 1)
      }
      const indexToken = process.argv.findIndex((arg) => arg.startsWith("--token"))
      if (indexToken >= 0) {
        const token = process.argv[indexToken].split("=")[1]
        if (token !== "null") {
          setToken(token)
        }
        process.argv.splice(indexToken, 1)
        next()
        return
      }
    }
    if (to.query.token) {
      const token = to.query.token;
      setToken(token.toString())
      next()
      return
    }
    // CHECK TOKEN
    if (!getToken()
      && from.fullPath !== constants.LOGIN_PATH
      && from.fullPath !== constants.REGISTER_PATH
      && to.fullPath !== constants.LOGIN_PATH
      && to.fullPath !== constants.ROOT_PATH) {
      // if (process.env.VUE_APP_BUILD !== "DESKTOP-APP") {
      //   next(constants.LOGIN_PATH)
      // }
      next()
      return
    }
  } catch (error) {
    console.log(error);
  }
  next()
}
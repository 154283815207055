<template>
  <div class="cl-inventory noselect flex h-max mx-20 my-3">
    <div class="mt-2">
      <div class="text-white mb-2 cl-font-20-bold flex">
        <img
          class="mr-1"
          src="@/assets/images/icons/shop.svg"
          alt=""
          srcset=""
        />
        SHOP
      </div>
      <v-list lines="two">
        <v-list-item
          v-for="(item, index) in lstItem"
          :key="index"
          class="hover:bg-customPurple4"
          @click="onHandleActiveItem(item)"
        >
          <template v-slot:title>
            <div class="w-max flex cl-font-16-bold uppercase">
              <div class="w-2 mr-3" style="margin-top: -2px">
                <v-icon
                  v-show="itemActive?.id === item.id"
                  color="#ff507a"
                  size="22"
                >
                  mdi-chevron-right
                </v-icon>
              </div>
              <div
                :class="
                  itemActive?.id === item.id
                    ? 'text-white'
                    : 'text-customPurple'
                "
              >
                {{ item.title }}
              </div>
            </div>
          </template>
        </v-list-item>
      </v-list>
    </div>

    <div class="flex-1 cl-components">
      <Transition mode="out-in" name="slide-fade">
        <component
          :is="itemActive?.component"
          :data="itemActive?.data"
          :lstSubCategory="itemActive?.lstSubCategory"
          @buyItem="onBuyItem"
        />
      </Transition>
    </div>

    <DialogItems
      v-if="isShowDialog"
      v-model="isShowDialog"
      :info="itemBuy"
      :success="buySuccess"
      :itemActive="itemActive"
      @closeDialog="onCloseDialog"
      @acceptDialog="onAcceptDialog"
    />

    <DialogBuyFailed
      v-model="isShowDialogBuyFailed"
      :nameItem="itemBuy?.ItemTitle"
      @closeDialog="isShowDialogBuyFailed = false"
    />
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import constants from "@/constants";
import { useStore } from "vuex";
import { key } from "@/store";
import { useI18n } from "vue-i18n";

// components
import Items from "./components/Items.vue";
import DialogItems from "@/components/core/dialog/shop/Items.vue";
import DialogBuyFailed from "@/components/core/dialog/shop/BuyFailed .vue";

// services
import { ShopService } from "@/modules/shop/services/shopServices";

export default {
  name: "ShopVue",

  components: {
    Items,
    DialogItems,
    DialogBuyFailed,
  },

  setup() {
    const isShowDialog = ref(false);
    const isShowDialogBuyFailed = ref(false);
    const itemActive = ref(null);
    const itemBuy = ref(null);
    const buySuccess = ref(false);
    const store = useStore(key);
    const { t } = useI18n();

    const lstItem = ref([
      {
        id: constants.SHOP_ITEMS.fashion,
        title: "thời trang",
        data: [],
        lstSubCategory: [
          {
            title: "Khung Premium",
            lstItem: [],
            category: constants.SUB_CATEGORY.frameGB,
          },
          {
            title: "Avatar",
            lstItem: [],
            category: constants.SUB_CATEGORY.avatarGB,
          },
        ],
        getData: () => getItemsCategory(),
        component: Items,
      },
      {
        id: constants.SHOP_ITEMS.supplement,
        title: "Bổ trợ",
        data: [],
        lstSubCategory: [
          {
            title: "Bổ trợ",
            lstItem: [],
            category: constants.CATEGORY.supplement,
            subCategory: [
              constants.SUB_CATEGORY.nameChangeCard,
              constants.SUB_CATEGORY.resetRankingCS2,
              constants.SUB_CATEGORY.boostCS2,
              constants.SUB_CATEGORY.CS2RankingTicket,
            ],
          },
        ],
        getData: () => getItemsSubCategory(),
        component: Items,
      },
    ]);

    function onHandleActiveItem(item) {
      itemActive.value = item;
      itemActive.value.getData();
    }

    function getItemsCategory() {
      try {
        itemActive.value.lstSubCategory.forEach(async (ele) => {
          const res = await ShopService.getItemsOntime(ele.category);
          if (res) {
            ele.lstItem = res.Data.ListItems;
          }
        });
      } catch (error) {
        console.log(error);
      }
    }

    async function getItemsSubCategory() {
      try {
        itemActive.value.lstSubCategory.forEach(async (item) => {
          const res = await ShopService.getItems(item.category);
          item.lstItem = [];
          item.loading = false;
          if (res) {
            item.subCategory.forEach((sub) => {
              item.lstItem = item.lstItem.concat(
                res.Data[0].ListItems.filter(
                  (val) => val.ItemSubcategoryId === sub
                )
              );
            });
          }
        });
      } catch (error) {
        console.log(error);
      }
    }

    function onCloseDialog() {
      isShowDialog.value = false;
    }

    async function onAcceptDialog(quantity) {
      try {
        isShowDialog.value = false;

        if (buySuccess.value) {
          buySuccess.value = false;
          if (itemActive.value.id === constants.SHOP_ITEMS.fashion) {
            itemActive.value.getData();
          }
          return;
        }

        const payload = {
          ItemId: itemBuy.value.ItemId,
          Quantity: parseInt(quantity),
          CurrencyType: constants.CURRENCY_TYPE.coin,
          Platform: 1,
        };

        await ShopService.transactionItems(payload);
        buySuccess.value = true;

        setTimeout(() => {
          isShowDialog.value = true;
        }, 500);
      } catch (error) {
        if (error === 401032) {
          isShowDialogBuyFailed.value = true;
          return;
        }
        store.dispatch("setSnackBar", {
          show: true,
          message: t(`error_code[${error}]`),
          type: constants.TYPE_SNACK_BAR.ERROR,
        });
      }
    }

    function onBuyItem(item) {
      itemBuy.value = item;
      isShowDialog.value = true;
    }

    onMounted(() => {
      itemActive.value = lstItem.value[0];
      itemActive.value.getData();
    });

    return {
      lstItem,
      itemBuy,
      itemActive,
      buySuccess,
      isShowDialog,
      isShowDialogBuyFailed,
      onBuyItem,
      onCloseDialog,
      onAcceptDialog,
      onHandleActiveItem,
    };
  },
};
</script>

<style lang="scss" scoped>
.cl-inventory {
  height: calc(100vh - 80px);
  overflow: hidden;
  .cl-components {
    min-width: 990px;
    padding-right: 5px;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
  }
  .v-list {
    width: 200px;
    min-width: max-content;
    height: max-content;
    color: #fff;
    margin-right: 20px;
    border-radius: 10px;
    border: none !important;
    margin-top: 0px !important;
    background: rgba(123, 114, 198, 0.08) !important;
  }
}
</style>

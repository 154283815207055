<template>
  <div class="noselect overflow-hidden">
    <div class="flex items-center cl-font-36-bold text-white ml-5">
      {{ rankingInfo?.season_name }}
      <v-chip class="ml-3" height="20" color="rgba(80, 47, 159, 1)">
        <span class="text-customPurple cl-font-14-bold">{{
          getTimeSeason()
        }}</span>
      </v-chip>
    </div>
    <div class="flex my-4 mx-5">
      <div
        class="w-40 h-9 flex justify-center items-center border-2 border-customPink rounded-md px-2"
      >
        <v-select
          v-model="modelSeason"
          class="w-40 h-9 mt-n1"
          variant="plain"
          density="compact"
          hide-no-data
          menu-icon=""
          item-value="season"
          :items="dataSeason"
        >
          <template v-slot:append-inner>
            <v-icon class="mr-n2" color="#7B72C6"> mdi-chevron-down </v-icon>
          </template>
          <template v-slot:item="{ props, item }">
            <div>
              <v-list-item class="hover:bg-customPurple" v-bind="props">
                <template v-slot:title>
                  <div class="cl-font-14-bold text-white">
                    {{ item.raw.season_name }}
                  </div>
                </template>
              </v-list-item>
            </div>
          </template>
          <template v-slot:selection="{ item }">
            <span class="text-customPink cl-font-14-bold maxline1">
              {{ item.props.title.season_name }}
            </span>
          </template>
        </v-select>
      </div>
      <div class="flex items-center w-80 h-9 ml-5 pr-2">
        <v-autocomplete
          density="compact"
          variant="outlined"
          class="select-user border-0 border-customPink rounded-md text-white"
          menu-icon=""
          placeholder="Tìm người chơi"
          clearable
          :no-filter="true"
          :items="items"
          :hide-details="true"
          return-object
          @update:search="(val) => searchUserFriends(val)"
          @update:modelValue="onClickUser"
        >
          <template v-slot:append-inner>
            <v-icon class="mr-n2" color="#7B72C6"> mdi-chevron-down </v-icon>
          </template>
          <template v-slot:no-data>
            <div class="cl-font-14-bold text-white flex justify-center">
              Danh sách rỗng
            </div>
          </template>
          <template v-slot:item="{ props, item }">
            <div ref="scrollContainer">
              <v-list-item
                class="cl-font-14-bold text-white border-b-2"
                v-bind="props"
                :title="item.raw.DisplayName"
              ></v-list-item>
            </div>
          </template>
          <template v-slot:selection="{ item }">
            <div class="cl-font-14-bold text-white">
              {{ item.raw.DisplayName }}
            </div>
          </template>
        </v-autocomplete>
      </div>
    </div>
    <div>
      <v-data-table
        id="id-virtual-rank"
        :headers="headers"
        :items="dataTable"
        :loading="loading"
        :height="getHeightTable()"
        no-filter
        disable-sort
        fixed-header
        hide-default-footer
        density="comfortable"
        item-value="top_rank"
        items-per-page="-1"
        loading-text="Đang tải dữ liệu"
        no-data-text="Không có dữ liệu"
        v-scroll.self="onScroll"
        @click:row="handleClickRow"
      >
        <template v-slot:[`header.rank_background`]="{ column }">
          <div class="flex justify-center">
            {{ column.title }}
            <img
              class="ml-3"
              src="@/assets/images/icons/outline_question.svg"
              alt=""
              srcset=""
            />
            <v-tooltip width="1000" activator="parent" location="bottom" attach>
              <img
                src="@/assets/images/ranks/rank_detail.svg"
                alt=""
                srcset=""
              />
            </v-tooltip>
          </div>
        </template>
        <template v-slot:[`item.top_rank`]="{ item }">
          <div class="relative flex items-center justify-center">
            <img
              v-if="item.top_rank < 6"
              class="absolute"
              :src="getBgImageTop(item.top_rank)"
              alt=""
              srcset=""
              style="z-index: -1"
            />
            <div
              :class="
                item.top_rank > 5 ? 'cl-font-12-bold py-0.5' : 'cl-font-16-bold'
              "
            >
              {{ item.top_rank }}
            </div>
          </div>
        </template>
        <template v-slot:[`item.display_name`]="{ item }">
          <div class="flex items-center">
            <img
              class="w-6 h-6 mr-2 rounded-full"
              :src="item.avatar_url"
              alt=""
              srcset=""
            />
            <div class="cl-font-14-bold max-w-64 maxline1">
              {{ item.display_name }}
            </div>
          </div>
        </template>
        <template v-slot:[`item.rank_background`]="{ item }">
          <div class="relative flex items-center justify-center w-full">
            <img
              class="w-26 h-9"
              :src="item.rank_background"
              alt=""
              srcset=""
              style="z-index: -1"
            />
            <div
              class="absolute cl-font-18-bold italic mt-2 ml-n9 w-16 flex justify-center maxline1"
              :style="{ color: item.elo_color }"
            >
              {{ item.elo }}
            </div>
          </div>
        </template>
      </v-data-table>
      <div
        v-show="loadingBottom"
        class="absolute z-10 bottom-0 flex justify-center items-center w-full"
      >
        <v-progress-linear
          height="2"
          color="#fff"
          indeterminate
        ></v-progress-linear>
      </div>
    </div>
  </div>
</template>

<script>
// mixins
import ranking from "@/mixins/ranking";

// service
import { BattleService } from "@/modules/battleCS2/services/battleServices";
import { AuthService } from "@/services/authService";

// rounter
import routesName from "@/routers/routesName";

// ultil
import { convertTextTime } from "@/helpers/utils";

export default {
  name: "cs2Ranking",
  mixins: [ranking],

  data: () => ({
    timeOutOnScroll: null,
    loading: false,
    loadingBottom: false,
    isLoadMore: true,
    currentSeason: 1,
    modelKeySearch: "",
    modelSeason: null,
    gameCode: "cs2",
    pageSize: 20,
    pageIndex: 1,
    dataSeason: [],
    headers: [
      {
        title: "Top",
        align: "center",
        sortable: false,
        key: "top_rank",
      },
      {
        title: "Người chơi",
        key: "display_name",
        sortable: false,
        align: "start",
      },
      { title: "Trận", key: "total_game", sortable: false, align: "start" },
      { title: "Kill", key: "kills", sortable: false, align: "start" },
      { title: "Assist", key: "assist", sortable: false, align: "start" },
      { title: "Death", key: "deaths", sortable: false, align: "start" },
      { title: "HS", key: "headshot", sortable: false, align: "start" },
      { title: "MVP", key: "mvp", sortable: false, align: "start" },
      { title: "ADR", key: "adr", sortable: false, align: "start" },
      { title: "1v1", key: "vs.1v1", sortable: false, align: "start" },
      { title: "1v2", key: "vs.1v2", sortable: false, align: "start" },
      { title: "1v3", key: "vs.1v3", sortable: false, align: "start" },
      { title: "1v4", key: "vs.1v4", sortable: false, align: "start" },
      { title: "1v5", key: "vs.1v5", sortable: false, align: "start" },
      {
        title: "Rank",
        key: "rank_background",
        sortable: false,
        align: "start",
      },
    ],
    dataTable: [],
    rankingInfo: {},

    timeOut: null,
    items: [],
  }),

  watch: {
    modelSeason() {
      this.resetParams();
      this.getListRanking();
    },
  },

  mounted() {
    this.getListSeason();
  },

  methods: {
    onClickUser(val) {
      this.modelKeySearch = val?.DisplayName;
      this.resetParams();
      this.getListRanking();
    },

    searchUserFriends(val) {
      try {
        this.items = [];
        if (!val || val.length < 1) return;
        if (this.timeOut) {
          clearTimeout(this.timeOut);
        }
        this.timeOut = setTimeout(async () => {
          const params = {
            display_name: val,
            pageSize: 50,
          };
          const response = await AuthService.searchUserAll(params);
          if (response) {
            this.items = response.Data;
          }
        }, 500);
      } catch (error) {
        console.error(error);
      }
    },

    getHeightTable() {
      return process.env.VUE_APP_BUILD == "DESKTOP-APP"
        ? "calc(100vh - 205px)"
        : "calc(100vh - 191px)";
    },

    onSearchDisplayName() {
      this.resetParams();
      this.getListRanking();
    },

    async getListSeason() {
      try {
        const res = await BattleService.getListSeason(this.gameCode);
        if (res.Data && res.Data.length) {
          this.dataSeason = res.Data.reverse();
          this.modelSeason = this.dataSeason[0].season;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getListRanking(loadMore) {
      try {
        this.isLoadMore = false;

        if (!loadMore) this.loading = true;
        else this.loadingBottom = true;

        const params = {
          page_size: this.pageSize,
          page_index: this.pageIndex,
          display_name: this.modelKeySearch,
          season: this.modelSeason,
        };

        const res = await BattleService.getRanking(this.gameCode, params);

        if (!loadMore) {
          this.dataTable = [];
          this.rankingInfo = res.Data;
        }

        this.isLoadMore = Boolean(
          res.Data && res.Data.ranking_user && res.Data.ranking_user.length
        );

        if (!this.isLoadMore) {
          return;
        }

        if (loadMore) {
          const dataTable = this.dataTable.concat(
            res.Data.ranking_user.filter((it) => it.top_rank !== 0)
          );
          this.dataTable = JSON.parse(JSON.stringify(dataTable));
          return;
        }

        this.dataTable = res.Data.ranking_user.filter(
          (it) => it.top_rank !== 0
        );
      } catch (error) {
        this.isLoadMore = false;
        this.dataTable = [];
      } finally {
        this.loading = false;
        setTimeout(() => {
          this.loadingBottom = false;
        }, 500);
      }
    },

    async handleClickRow(item, row) {
      try {
        this.$router.push({
          name: routesName.PROFILE,
          params: {
            userId: row.item.user_id,
          },
        });
      } catch (error) {
        console.log(error);
      }
    },

    onClickSeason(value) {
      this.currentSeason = value;
    },

    onScroll() {
      this.timeOutOnScroll && clearTimeout(this.timeOutOnScroll);
      this.timeOutOnScroll = setTimeout(() => {
        var item = document.getElementById("id-virtual-rank");
        if (
          Math.abs(item.scrollHeight - item.clientHeight - item.scrollTop) <
            450 &&
          this.isLoadMore
        ) {
          this.pageIndex += 1;
          this.getListRanking(true);
        }
      }, 100);
    },

    getTimeSeason() {
      if (
        !this.rankingInfo ||
        !this.rankingInfo.start_time ||
        !this.rankingInfo.finish_time
      ) {
        return "";
      }
      const startTime = new Date(this.rankingInfo?.start_time * 1000);
      const finishTime = new Date(this.rankingInfo?.finish_time * 1000);

      return `${convertTextTime(startTime.getDate())}/${convertTextTime(
        startTime.getMonth() + 1
      )}/${convertTextTime(startTime.getFullYear())} - ${convertTextTime(
        finishTime.getDate()
      )}/${convertTextTime(finishTime.getMonth() + 1)}/${convertTextTime(
        finishTime.getFullYear()
      )}`;
    },

    resetParams() {
      document.getElementById("id-virtual-rank").scrollTop = 0;
      this.pageIndex = 1;
      this.pageSize = 20;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.v-overlay__content) {
  margin-right: 20px;
  background: transparent !important;
}
::v-deep(.v-text-field input) {
  color: rgba(255, 255, 255, 1) !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

#id-virtual-rank {
  overflow-y: auto !important;
}
.v-table {
  color: #fff !important;
  background: transparent !important;
  ::v-deep(hr) {
    display: none;
  }
}
::v-deep(.v-table__wrapper) {
  overflow: initial !important;
}
::v-deep(.v-data-table__td) {
  border: 0px !important;
}
::v-deep(.v-data-table__tr:nth-child(even)) {
  background: rgba(255, 255, 255, 0.05);
}
::v-deep(.v-data-table__tr:nth-child(odd)) {
  background: rgba(0, 0, 0, 0.3);
}
::v-deep(
    .v-table.v-table--fixed-header > .v-table__wrapper > table > thead > tr > th
  ) {
  background: rgba(0, 0, 0, 1);
}
::v-deep(.v-data-table__th) {
  background: rgba(0, 0, 0, 1);
}
::v-deep(.v-data-table-header__content) {
  font-size: 14px;
  font-weight: 700;
}
::v-deep(.v-data-table__th:last-child) {
  width: 150px !important;
  color: rgba(230, 186, 32, 1);
}
::v-deep(.v-data-table__td) {
  font-size: 14px;
  font-weight: 400;
}
::v-deep(.v-data-table__tr:hover) {
  background: rgba(123, 114, 198, 0.2);
}

.select-user {
  ::v-deep(.v-input__control) {
    height: 36px !important;
  }
  ::v-deep(.v-field__outline) {
    --v-field-border-width: 2px !important;
    --v-field-border-opacity: 1 !important;
  }
}
</style>

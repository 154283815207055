<template>
  <div class="noselect overflow-hidden">
    <div class="flex items-center cl-font-36-bold text-white ml-5">
      Xếp hạng đấu thường
    </div>
    <TableRankingVue
      ref="tableRanking"
      :headers="headers"
      :lstFilter="dataRankingMode"
      :dataTable="dataTable"
      :isLoadMore="isLoadMore"
      :loading="loading"
      :loadingBottom="loadingBottom"
      @changeSearchRequest="onSearchRequest"
    />
  </div>
</template>

<script>
// service
import { BattleService } from "@/modules/battleCS2/services/battleServices";

// components
import TableRankingVue from "./components/TableRanking.vue";

export default {
  name: "cs2Ranking",

  components: {
    TableRankingVue,
  },

  data: () => ({
    isLoadMore: true,
    loading: false,
    loadingBottom: false,
    pageSize: 20,
    pageIndex: 1,
    dataRankingMode: [],
    headers: [],
    dataTable: [],
  }),

  mounted() {
    this.getListRankingMode();
  },

  methods: {
    onSearchRequest(loadMore) {
      if (!loadMore) {
        this.resetParams();
        this.headers = this.$refs.tableRanking.getModelSelected().headers;
      } else {
        this.pageIndex += 1;
      }
      this.getListRankingNormal(loadMore);
    },

    async getListRankingMode() {
      try {
        const res = await BattleService.getRankingMode();
        if (res.Data && res.Data.length) {
          this.dataRankingMode = res.Data.map((item) => ({
            id: item.mode,
            title: item.title,
            headers: item.headers_data,
          }));
          if (this.$refs.tableRanking && this.dataRankingMode.length) {
            this.headers = this.dataRankingMode[0].headers;
            this.$refs.tableRanking.setModelSelected(this.dataRankingMode[0]);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.getListRankingNormal();
      }
    },

    async getListRankingNormal(loadMore) {
      try {
        this.isLoadMore = false;
        this.loading = !loadMore;
        this.loadingBottom = loadMore;
        
        const params = {
          size: this.pageSize,
          index: this.pageIndex,
          mode: this.$refs.tableRanking.getModelSelected().id ?? null,
          user_uuid: this.$refs.tableRanking.getModelKeySearch() ?? null,
        };

        const res = await BattleService.getRankingNormal(params);
        
        if (!loadMore) {
          this.dataTable = [];
        }

        const rankingUsers = res.Data || [];
        this.isLoadMore = rankingUsers.length > 0;

        if (!this.isLoadMore) {
          return;
        }

        this.dataTable = loadMore
          ? this.dataTable.concat(rankingUsers)
          : rankingUsers;
      } catch (error) {
        this.dataTable = [];
      } finally {
        this.loading = false;
        if (this.loadingBottom) {
          setTimeout(() => {
            this.loadingBottom = false;
          }, 500);
        }
      }
    },

    resetParams() {
      this.pageIndex = 1;
      this.pageSize = 20;
    },
  },
};
</script>
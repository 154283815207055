<template>
  <v-dialog v-model="dialog" max-width="400" max-height="300" persistent>
    <div class="cl-dialog">
      <img
        class="cl-bg"
        src="@/assets/images/dialog/bg_count_down.svg"
        alt=""
      />
      <v-btn
        class="absolute top-2 right-2"
        size="24"
        variant="plain"
        color="#fff"
        @click.stop="onCloseDialog"
      >
        <v-icon size="24">mdi-close</v-icon>
      </v-btn>
      <div class="absolute top-6 cl-title">Nhập mật khẩu</div>
      <div class="w-full pa-5 mt-8">
        <v-text-field
          v-model="password"
          flat
          dirty
          autofocus
          color="#7B72C6"
          density="compact"
          variant="outlined"
          base-color="#7B72C6"
          bg-color="rgba(123, 114, 198, 0.3)"
          hide-details
        >
        </v-text-field>
        <div class="d-flex mt-2" style="gap: 10px">
          <v-btn class="cl-btn-close mt-2" @click="onCloseDialog">
            <span class="cl-font-18-bold"> đóng </span>
          </v-btn>
          <v-btn class="cl-btn-accept mt-2" @click="onAccept">
            <span class="cl-font-18-bold"> gửi </span>
          </v-btn>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { ref, watchEffect } from "vue";

export default {
  name: "dialogPassword",
  emit: ["closeDialog", "acceptDialog"],
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const dialog = ref(props.showDialog);
    const password = ref();
    watchEffect(() => {
      dialog.value = props.showDialog;
    });
    const onCloseDialog = () => {
      password.value = null;
      emit("closeDialog");
    };
    const onAccept = () => {
      emit("acceptDialog", password.value);
    };
    return {
      dialog,
      password,
      onAccept,
      onCloseDialog,
    };
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
::v-deep(.v-text-field input) {
  color: #ffffff !important;
  border-radius: 3px !important;
}
.cl-dialog {
  width: 400px;
  height: 200px;
  padding: 10px;
  .cl-btn-accept {
    flex: 1;
    height: 40px;
  }
  .cl-btn-close {
    flex: 1;
    color: #ffffff;
    border: 1px solid #ffffff;
    background: transparent;
  }
}
</style>